import React, { useState } from 'react';
import DetailsPanel from './DetailsPanel';
import BookingCreationDetails from './creationDetails/BookingCreationDetails';
import FlightsCreationDetails from './creationDetails/FlightsCreationDetails';
import ContactCreationDetails from './creationDetails/ContactCreationDetails';
import PassengersCreationDetails from './creationDetails/PassengersCreationDetails';
import CreateBookingParameters from '../types/CreateBookingParameters';
import PassengerTypes from '../types/PassengerTypes';
import ContactDetails from '../types/ContactDetails';
import Flight from '../types/Flight';
import Route from '../types/Route';

interface CreateBookingProps {
  createBooking: Function;
  creating: boolean;
  routes: Route[];
  setRoutes: Function;
}

const CreateBooking: React.FC<CreateBookingProps> = ({ createBooking, creating, routes, setRoutes }) => {
  const [creatingBookingParams, setCreateParams] = useState<CreateBookingParameters>({
    "ProductClass": "basic",
    "PassengerTypes": { 
      "Adults": 2,
      "Children": 0
    },
    "FlightOptions": [
      {
        "DepartureDate": null,
        "Origin": null,
        "Destination": null,
        "FlightNumber": null,
        "IncludeReturnFlight": null,
        "Ssrs": [] 
      }
    ],
    "PassengerDetails": [],
    "ThirdPartyRecordLocator": {
      "OwningSystemCode": "",
      "RecordCode": ""

    },
    "ContactDetails": null
  });

  const setProductClass = (productClass: string) => {
    setCreateParams(prevState => ({
      ...prevState,
      ProductClass: productClass
    }));
  };

  const setExternalBookingReference = (org: string, reference: string) => {
    const updatedThirdPartyRecordLocator = {
      "OwningSystemCode": org,
      "RecordCode": reference
    }

    setCreateParams(prevState => ({
      ...prevState,
      ThirdPartyRecordLocator: updatedThirdPartyRecordLocator
    }));
  };

  const addFlight = (event: Event) => {
    event.preventDefault();
    const newFlight: Flight = {
      "DepartureDate": null,
      "Origin": null,
      "Destination": null,
      "FlightNumber": null,
      "IncludeReturnFlight": null,
      "Ssrs": [] 
    };

    setCreateParams(prevState => ({
      ...prevState,
      FlightOptions: [...prevState.FlightOptions, newFlight]
    }));
  };

  const onFlightChange = (index: number, flight: Flight) => {
    console.log(`updating flight with index: ${index}. Flight ${flight}`);

    var flightOptions = structuredClone(creatingBookingParams.FlightOptions);
    flightOptions[index] = flight;

    setCreateParams(prevState => ({
      ...prevState,
      FlightOptions: flightOptions
    }))
  }

  const onFlightDelete = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (event) {
      event.preventDefault();
    }

    var flightOptions = creatingBookingParams.FlightOptions;
    var updatedFlightOptions = flightOptions.slice(0, index).concat(flightOptions.slice(index + 1));

    setCreateParams(prevState => ({
      ...prevState,
      FlightOptions: updatedFlightOptions
    }))
  }

  const onPassengerTypesChange = (event: React.ChangeEvent<HTMLInputElement>, passengerTypes: PassengerTypes) => {
    if (event) {
      event.preventDefault();
    }

    setCreateParams(prevState => ({
      ...prevState,
      PassengerTypes: passengerTypes
    }))
  }

  const onContactDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, contactDetails: ContactDetails) => {
    if (event) {
      event.preventDefault();
    }

    setCreateParams(prevState => ({
      ...prevState,
      ContactDetails: contactDetails
    }))
  }

  return (
    <div className="flex flex-col overflow-hidden relative">
      <div className="flex flex-1 overflow-x-auto gap-4">
        <DetailsPanel title="Booking" content={
          <BookingCreationDetails 
            productClass={creatingBookingParams.ProductClass}
            onProdClassChange={setProductClass}
            externalBookingReference={creatingBookingParams.ThirdPartyRecordLocator}
            onExtBookingRefChange={setExternalBookingReference} />
        }/>
        <DetailsPanel title="Flight" addFunction={addFlight} content={
          <FlightsCreationDetails 
            flights={creatingBookingParams.FlightOptions} 
            routes={routes}
            setRoutes={setRoutes}
            onFlightChange={onFlightChange}
            onFlightDelete={onFlightDelete} />
        } />
        <DetailsPanel title="Passenger" content={
          <PassengersCreationDetails 
            passengerTypes={creatingBookingParams.PassengerTypes}
            onPassengerTypesChange={onPassengerTypesChange}
            passengers={creatingBookingParams.PassengerDetails}/>
        } />
        <DetailsPanel title="Contact" content={
          <ContactCreationDetails 
            contactDetails={creatingBookingParams.ContactDetails}
            onContactDetailsChange={onContactDetailsChange}/>
        }/>
      </div>
      <button
        data-testid="create-button"
        className={`absolute bottom-0 right-0 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${creating ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={(e) => createBooking(e, creatingBookingParams)}
        disabled={creating}
      >
        {creating ? 'Creating Booking...' : 'Create Booking'}
      </button>
    </div>
  );
};

export default CreateBooking;