import React from 'react';
import PassengerCreationDetails from './PassengerCreationDetails';
import Passenger from '../../types/PassengerDetail';
import PassengerTypes from '../../types/PassengerTypes';

interface PassengersCreationDetailsProps {
  passengerTypes: PassengerTypes;
  onPassengerTypesChange: Function;
  passengers: Passenger[];
}

const PassengersCreationDetails : React.FC<PassengersCreationDetailsProps> = ({ passengerTypes, onPassengerTypesChange, passengers }) => {
  const SetAdultCount = (e : React.ChangeEvent<HTMLInputElement>) => {
    var updatedPassengerTypes = {
      ...passengerTypes,
      Adults: e.target.value
    }

    onPassengerTypesChange(e, updatedPassengerTypes);
  }

  const SetChildCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    var updatedPassengerTypes = {
      ...passengerTypes,
      Children: e.target.value
    }

    onPassengerTypesChange(e, updatedPassengerTypes);
  }

  return (
    <form className='max-w-full'>
      <label htmlFor="adultCount" className="block text-gray-700 font-bold mb-2">Adults</label>
      <input
        type="number"
        min="1"
        defaultValue={passengerTypes.Adults}
        id="adultCount"
        name="adultCount"
        onBlur={(e) => SetAdultCount(e)}
        className="
          w-full
          appearance-none
          bg-white
          border
          border-gray-400
          rounded
          py-2
          pl-4
          mb-4
          leading-tight
          focus:outline-none
          focus:bg-white
          focus:border-gray-500" />
      <label htmlFor="childCount" className="block text-gray-700 font-bold mb-2">Children</label>
      <input
        type="number"
        min="0"
        defaultValue={passengerTypes.Children}
        id="childCount"
        name="childCount"
        onBlur={(e) => SetChildCount(e)}
        className="
          w-full
          appearance-none
          bg-white
          border
          border-gray-400
          rounded
          py-2
          pl-4
          mb-4
          leading-tight
          focus:outline-none
          focus:bg-white
          focus:border-gray-500" />
      {
        passengers.map((passenger: Passenger, index: number) => (
          <PassengerCreationDetails key={index} passenger={passenger} />
        ))
      }
    </form>
  );
};

export default PassengersCreationDetails