import React, { useState, useEffect } from 'react';
import HeaderBar from './components/HeaderBar';
import BookingReferenceList from './components/CreatedBookingItemList';
import CreateBooking from './components/CreateBooking';
import { ToastContainer, toast } from 'react-toastify';
import BookingService from './services/bookingService';
import CreateBookingParameters from './types/CreateBookingParameters';
import BookingReferenceItem from './types/CreatedBooking';

function App() {
  const [creatingBooking, setCreating] = useState(false);
  const [bookingCreationHistory, setBookingCreationHistory] = useState<BookingReferenceItem[]>([]);
  const [routes, setRoutes] = useState([]);
  const environment = process.env.REACT_APP_ENVIRONMENT;
  useEffect(() => {
    // Load bookings from local storage when the component mounts
    const savedBookings: BookingReferenceItem[] = JSON.parse(localStorage.getItem('bookingCreationHistory') || '[]') as BookingReferenceItem[];
    setBookingCreationHistory(savedBookings);
    BookingService.getRoutes()
      .then((data) => {
        setRoutes(data);
      });
  }, []);

  const createBooking = async (_: any, bookingParams: CreateBookingParameters) => {
    console.log("Creating booking...");
    setCreating(true);

    try {
      const data = await BookingService.createBookingRequest(bookingParams);
      const bookingReference = data.bookingReference;
      const updatedBookings = [...bookingCreationHistory, { "reference": bookingReference, "cancelled": false }];

      setBookingCreationHistory(updatedBookings);

      localStorage.setItem('bookingCreationHistory', JSON.stringify(updatedBookings));

      toast.success('Booking created successfully!');

    } catch (error) {

      console.error('Fetch error:', error);
    
      if (error instanceof Error) {
        toast.error(`${error.message}`);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setCreating(false);
    }
  };

  const handleCancelBooking = (bookingReference: string) => {
    setBookingCreationHistory(currentBookings => {
      const updatedBookings = currentBookings.map(booking =>
        booking.reference === bookingReference ? { ...booking, cancelled: true } : booking
      );
      localStorage.setItem('bookingCreationHistory', JSON.stringify(updatedBookings));
      return updatedBookings
    }
    );
  };

  return (
    <div className="
      grid
      grid-rows-main
      grid-cols-main
      h-screen
      w-screen
      gap-4
      p-4
      bg-gray-100
      max-w-3xl
      mx-auto
      relative">
      <HeaderBar environment={environment ?? ""} />
      <BookingReferenceList bookings={bookingCreationHistory} onCancelBooking={handleCancelBooking} />
      <CreateBooking createBooking={createBooking} creating={creatingBooking} routes={routes} setRoutes={setRoutes}/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
