import React from 'react';

interface HeaderBarProps {
  environment: string;
}

const HeaderBar : React.FC<HeaderBarProps> = ({ environment }) => {
  return (
    <div id="header" className="col-span-full bg-green-500 text-white font-bold py-1 shadow-lg px-4 rounded-md">
      <h1>Test Booking Creation: {environment}</h1>
    </div>
  );
};

export default HeaderBar;
