const toCarrierAndNumber = function(flightNumber : string | null) {
    if (!flightNumber) return null;
    var carrierCode = flightNumber.substring(0, 2);
    var flightIdentifier = flightNumber.substring(2, 6);

    return {
        carrierCode: carrierCode,
        flightNumber: flightIdentifier
    }
}

export { toCarrierAndNumber };