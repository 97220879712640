import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "unknown",
    authority: "https://login.microsoftonline.com/34fc6d82-5243-4b83-a798-5de6141de037"
  }
}

document.title = `[${process.env.REACT_APP_ENVIRONMENT_SHORT}] Nsk Test Setup UI`;

const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <MsalProvider instance={pca}>
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </MsalProvider>
);
