import React from 'react';
import ExternalBookingReference from '../../types/ExternalBookingReference';

interface BookingCreationDetailsProps {
  productClass: string;
  onProdClassChange: Function;
  externalBookingReference: ExternalBookingReference;
  onExtBookingRefChange: Function;
}

const BookingCreationDetails: React.FC<BookingCreationDetailsProps> = ({productClass, onProdClassChange, externalBookingReference, onExtBookingRefChange}) => {
  return (
    <form className='max-w-full'>
      <label htmlFor="brandedFare" className="block text-gray-700 font-bold mb-2">Branded Fare</label>
      <select
        name="brandedFare"
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        defaultValue={productClass} onChange={(e) => onProdClassChange(e.target.value)}
      >
        <option value="basic">Basic</option>
        <option value="plus">Plus</option>
        <option value="max">Max</option>
      </select>
      <label className="block text-gray-700 font-bold mt-4">External Booking Reference</label>
      <div className='flex max-w-full'>
        <div className="w-24">
          <label htmlFor="org-code" className="ml-2 px-1 text-xs bg-white text-gray-700 mb-2 relative z-20">Organisation</label>
          <input
            type="text"
            id="org-code"
            name="orgCode"
            defaultValue={externalBookingReference.OwningSystemCode}
            onBlur={(e) => onExtBookingRefChange(e.target.value, externalBookingReference.RecordCode)}
            className="
              max-w-full
              appearance-none
              bg-white
              border
              border-r-0
              border-gray-400
              rounded-l
              -mt-8
              py-2
              pl-4
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-gray-500
              relative
              z-10"
            placeholder="GOS"
          />
        </div>
        <div className="w-48 relative">
          <label htmlFor="reference" className="ml-2 px-1 text-xs bg-white text-gray-700 mb-2 relative z-20">Reference</label>
          <input
            type="text"
            id="reference"
            name="reference"
            defaultValue={externalBookingReference.RecordCode}
            onBlur={(e) => onExtBookingRefChange(externalBookingReference.OwningSystemCode, e.target.value)}
            className="
              w-48
              appearance-none
              bg-white
              border
              border-l-0
              border-gray-400
              rounded-r
              -mt-2
              py-2
              pr-4
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-gray-500
              relative
              z-10"
            placeholder="0123456789"
          />
        </div>
      </div>
    </form>
  );
};

export default BookingCreationDetails