import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface CustomDatePickerProps {
  title: string;
  onDateChange: Function;
  onMonthChange: Function;
  allowedDates: Date[] | null;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({title, onDateChange, onMonthChange, allowedDates }) => {
  const [currMonth, setCurrMonth] = useState<number>(new Date().getMonth() + 1);
  const [currYear, setCurrYear] = useState<number>(new Date().getFullYear());
  const [pickedDate, setPickedDate] = useState<boolean>(false); 


  const handleOpen = () => {
    if (pickedDate) {
      onMonthChange(currYear, currMonth);
    } else {
      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();

      setCurrMonth(month);
      setCurrYear(year);

      onMonthChange(year, month);
    }
  }

  const shouldDisableDate = (date: any): boolean => {

    const nativeDate = date.toDate()
    if (!allowedDates) return false;
    return !allowedDates.some(
      (allowedDate) =>
        nativeDate.getFullYear() === new Date(allowedDate).getFullYear() &&
        nativeDate.getMonth() === new Date(allowedDate).getMonth() &&
        nativeDate.getDate() === new Date(allowedDate).getDate()
    );
  };

  const onDateChangeWrapper = (newValue: any) => {    
    setPickedDate(newValue != null);
    onDateChange(newValue);
  }


  return (
    <div>
      <label htmlFor={`${title}-date`} className="block text-gray-700 font-bold mb-2">{title ? title : 'Date' }</label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Select Date"
          shouldDisableDate={shouldDisableDate}
          onChange={(newValue) => onDateChangeWrapper(newValue)}
          onOpen={handleOpen}
          disablePast={true}
          onMonthChange={(newMonth) => {
            var month = newMonth.toDate().getMonth() + 1;
            var year = newMonth.toDate().getFullYear();

            setCurrMonth(month);
            setCurrYear(year);
            
            onMonthChange(year, month);
          }}
          slotProps={{ textField: { 
            size: 'small',
            fullWidth: true,
          }, 
          field: { clearable: true } 
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDatePicker;
