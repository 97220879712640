import React from 'react';
import FlightCreationDetails from './FlightCreationDetails';
import Flight from '../../types/Flight';
import Route from '../../types/Route';


interface FlightsCreationDetailsProps {
  flights: Flight[];
  onFlightChange: Function;
  onFlightDelete: Function;
  routes: Route[];
  setRoutes: Function;
}

const FlightsCreationDetails : React.FC<FlightsCreationDetailsProps> = ({ flights, onFlightChange, onFlightDelete, routes, setRoutes }) => {
  var hasFlights = (flights && flights.length > 0);
  
  if(!hasFlights){
    return <form className='max-w-full'></form>
  }

  return (
    <form className='max-w-full max-h-full overflow-y-auto' onSubmit={(e) => e.preventDefault()}>
      {
          flights.map((flight, index) => (
            <FlightCreationDetails key={index} index={index} flight={flight} onFlightChange={onFlightChange} onFlightDelete={onFlightDelete} routes={routes} setRoutes={setRoutes}/>
          ))
        }
    </form>
  );
};

export default FlightsCreationDetails