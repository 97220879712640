import React from 'react';
import ContactDetails from '../../types/ContactDetails';

interface ContactCreationDetailProps {
  contactDetails: null | ContactDetails;
  onContactDetailsChange: Function;
}

const ContactCreationDetails : React.FC<ContactCreationDetailProps> = ({contactDetails, onContactDetailsChange}) => {
  var contactTitle = contactDetails?.ContactTitle ?? "";
  var contactFirstName = contactDetails?.ContactFirstName ?? "";
  var contactLastName = contactDetails?.ContactLastName ?? "";
  var contactEmail = contactDetails?.ContactEmail ?? "";

  const OnTitleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    var updatedTitle = event.target.value !== "" ? event.target.value : null;

    var updatedContactDetails = {
      ...contactDetails,
      Title: updatedTitle
    }

    onContactDetailsChange(event, updatedContactDetails)
  }

  const OnFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var updatedFirstName = event.target.value !== "" ? event.target.value : null;

    var updatedContactDetails = {
      ...contactDetails,
      FirstName: updatedFirstName
    }

    onContactDetailsChange(event, updatedContactDetails)
  }

  const OnLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var updatedLastName = event.target.value !== "" ? event.target.value : null;

    var updatedContactDetails = {
      ...contactDetails,
      LastName: updatedLastName
    }

    onContactDetailsChange(event, updatedContactDetails)
  }
  
  const OnEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var updatedEmailAddress = event.target.value !== "" ? event.target.value : null;

    var updatedContactDetails = {
      ...contactDetails,
      EmailAddress: updatedEmailAddress
    }

    onContactDetailsChange(event, updatedContactDetails)
  }

  return (
    <form className='max-w-full'>
      <label htmlFor="contactTitle" className="block text-gray-700 font-bold mb-2">Title</label>
      <select
        name="contactTitle"
        className="
          block
          appearance-none
          w-full
          bg-white
          border
          border-gray-400
          hover:border-gray-500
          px-4
          py-2
          mb-4
          rounded
          leading-tight
          focus:outline-none
          focus:shadow-outline"
        defaultValue={contactTitle}
        onChange={(e) => OnTitleChange(e)}
      >
        <option value="">Random</option>
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Miss">Miss</option>
        <option value="Mstr">Mstr</option>
      </select>
      <label htmlFor="contactFirstName" className="block text-gray-700 font-bold mb-2">First name</label>
      <input
        type="text"
        id="contactFirstName"
        name="contactFirstName"
        defaultValue={contactFirstName}
        onBlur={(e) => OnFirstNameChange(e)}
        className="
          w-full
          appearance-none
          bg-white
          border
          border-gray-400
          rounded
          py-2
          pl-4
          mb-4
          leading-tight
          focus:outline-none
          focus:bg-white
          focus:border-gray-500" />
      <label htmlFor="contactLastName" className="block text-gray-700 font-bold mb-2">Last name</label>
      <input
        type="text"
        id="contactLastName"
        name="contactLastName"
        defaultValue={contactLastName}
        onBlur={(e) => OnLastNameChange(e)}
        className="
          w-full
          appearance-none
          bg-white
          border
          border-gray-400
          rounded
          py-2
          pl-4
          mb-4
          leading-tight
          focus:outline-none
          focus:bg-white
          focus:border-gray-500" />
      <label htmlFor="contactEmail" className="block text-gray-700 font-bold mb-2">Email</label>
      <input
        type="email"
        id="contactEmail"
        name="contactEmail"
        defaultValue={contactEmail}
        onBlur={(e) => OnEmailAddressChange(e)}
        className="
          w-full
          appearance-none
          bg-white
          border
          border-gray-400
          rounded
          py-2
          pl-4
          mb-4
          leading-tight
          focus:outline-none
          focus:bg-white
          focus:border-gray-500" />
    </form>
  );
};

export default ContactCreationDetails