import React from 'react';
import DeleteBookingButton from './DeleteBookingButton';

interface CreatedBookingItemProps {
  reference: string;
  cancelled: boolean;
  onCancelBooking: Function;
}

const CreatedBookingItem : React.FC<CreatedBookingItemProps> = ({reference, cancelled, onCancelBooking}) => {
  return (
    <li data-testid="created-booking" className={`bg-gray-100 p-2 rounded-md mb-2 flex justify-between items-center`}>
      <span className={`${cancelled ? 'line-through' : ''}`}>{reference}</span>
      <DeleteBookingButton bookingReference={reference} cancelled={cancelled} onCancelBooking={onCancelBooking} />
    </li>
  );
};

export default CreatedBookingItem;
